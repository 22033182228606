/* About */

.about h2 {
  margin: 20px 0;
}

.about h3 {
  margin-bottom: 20px;
}

.about-list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.about-links {
  display: block;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  color: var(--dark);
  font-size: 15px;
  font-family: 'Poppins';
}

.about-links:hover {
  background-color: #f5f5f5;
  border-radius: 3px;
}

.about-list i {
  display: flex;
  align-items: center;
  padding-right: 8px;
  font-size: 16px;
}

.about-links a {
  display: flex;
  align-items: center;
  color: var(--dark);
}

.about p:last-of-type {
  padding-bottom: 10px;
}

@media (min-width: 992px) {
  .about-list {
    flex-direction: row;
  }
}
