/* About */

.resume h2 {
  margin: 20px 0 40px 0;
}

.resume h3 {
  margin-bottom: 20px;
}

.resume-list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.resume-links {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  color: var(--dark);
  font-size: 15px;
  font-family: 'Poppins';
}

.resume-links:hover {
  background-color: #f5f5f5;
  border-radius: 3px;
}

.resume-list i {
  display: flex;
  align-items: center;
  padding-right: 8px;
  font-size: 16px;
}

.resume-links a {
  display: flex;
  align-items: center;
  color: var(--dark);
}

.resume p:last-of-type {
  padding-bottom: 10px;
}
