/* NAVBAR STYLING STARTS */
.navbar .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  color: #fff;
}

.logo-section {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.logo-section h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.6em;
  color: #ffffff;
}

/* .logo {
  width: 30%;
  justify-content: flex-end;
  padding: 5px;
  font-size: 4.5rem;
  text-align: right;
} */

.nav-links a {
  color: #fff;
}

.menu {
  display: none;
  position: absolute;
  z-index: 1;
  right: 0;
  left: 0;
  text-align: center;
  padding: 16px 0;
  gap: 1em;
  font-size: 18px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.menu li:hover {
  display: inline-block;
  background-color: #8080802b;
  transition: 0.3s ease;
}

.menu li {
  padding: 5px 14px;
}

input[type='checkbox'] {
  display: none;
}

.hamburger {
  display: block;
  font-size: 24px;
  user-select: none;
}

input[type='checkbox']:checked ~ .menu {
  display: flex;
  flex-direction: column;
  background-color: #000000a6;
}

.menu li + li {
  margin-top: 12px;
}

@media (min-width: 768px) {
  .menu {
    display: flex;
    position: relative;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
  }

  .hamburger {
    display: none;
  }

  .menu li:hover {
    background-color: #0000002b;
    border-radius: 5px;
    transition: 0.3s ease;
  }

  .menu li + li {
    margin-top: 0px;
  }

  input[type='checkbox']:checked ~ .menu {
    display: flex;
    position: relative;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    background-color: #00000000;
  }
}
