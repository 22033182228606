/* Footer */

footer {
  text-align: center;
  background-color: var(--dark);
  padding: 130px 10%;
  color: #ffffff;
  line-height: 22px;
  font-weight: 400;
}

footer h2 {
  color: #fff;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

footer p {
  color: #fff;
}

footer ul {
  margin-top: 22px;
  margin-bottom: 22px;
}

footer ul li {
  display: inline-block;
  margin: 7px;
}

footer ul li a i {
  color: var(--dark);
  height: 32px;
  width: 32px;
  border-radius: 10%;
  padding: 10px 10px 4px 10px;
  line-height: 32px !important;
  background-color: #f6f6f6;
}

footer ul li a:hover i {
  color: #fff;
  background-color: var(--dark);
  transition: 0.3s ease;
  outline: 3px solid rgb(255, 255, 255);
}

/* media query for larger screens */

@media (min-width: 768px) {
  footer {
    padding: 130px 15%;
  }
}

@media (min-width: 992px) {
  footer {
    padding: 130px 29%;
  }
}

@media (min-width: 1200px) {
  footer {
    padding: 130px 29%;
  }
}

@media screen and (min-width: 1200px) {
  footer {
    padding: 130px 29%;
  }
}
