/* contact */

.contact h2 {
  margin: 20px 0 40px 0;
}

.contact-intro {
  padding-bottom: 40px;
}

.contact-list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.contact-links {
  display: block;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  color: var(--dark);
  font-size: 15px;
  font-family: 'Poppins';
}

.contact-links:hover {
  background-color: #f5f5f5;
  border-radius: 3px;
}

.contact-list i {
  display: flex;
  align-items: center;
  padding-right: 8px;
  font-size: 16px;
}

.contact-links a {
  display: flex;
  align-items: center;
  color: var(--dark);
}

.contact p:last-of-type {
  padding-bottom: 10px;
}
